<template>
  <el-dialog :title="isEdit ? '编辑企业' : '新增企业'" width="450px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form label-width="70px" :model="form" :rules="rules" ref="form">
      <el-form-item label="企业账号" prop="account">
        <el-input placeholder="请输入" v-model="form.account" clearable></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="name">
        <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="租户编码" prop="tenantCode">
        <el-input placeholder="请输入" v-model="form.tenantCode" clearable></el-input>
      </el-form-item>
      <el-form-item label="企业图标" prop="iconUrl">
        <image-upload :url.sync="form.iconUrl" @success="handleAvatarSuccess" :params="{ businessType: 'enterprise_icon' }" :fileSize="2" fileType="image" />
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="!isEdit">
        <el-input placeholder="请输入" v-model="form.password" clearable></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="linkman">
        <el-input placeholder="请输入" v-model="form.linkman" clearable></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input placeholder="请输入" v-model="form.phone" clearable></el-input>
      </el-form-item>
      <el-form-item label="企业邮箱">
        <el-input placeholder="请输入" v-model="form.email" clearable :disabled="isEdit"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/operate/account'
import imageUpload from '@/components/imageUpload'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
  },
  components: { imageUpload },
  data() {
    return {
      form: {},
      rules: {
        account: [{ required: true, message: '企业账号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        linkman: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        tenantCode: [{ required: true, message: '请输入租户编码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      isEdit: false,
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.isEdit = !_.isEmpty(this.detail)
        this.form = this.isEdit ? this.detail : {}
      } else {
        this.$refs['form'].resetFields()
      }
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    add() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        let params = this.form
        let handleType = this.isEdit ? 'modify' : 'add'
        api[handleType](params).then(() => {
          this.$msg.success('操作成功')
          this.$emit('close')
        })
      })
    },
    handleAvatarSuccess(res, file) {
      const { ossUrl } = res.data
      this.$set(this.form, 'iconUrl', ossUrl)
    },
  },
}
</script>
