<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="企业账号">
            <el-input placeholder="请输入" v-model="form.account" clearable></el-input>
          </el-form-item>
          <el-form-item label="企业名称">
            <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
            <el-button type="primary" plain @click="handleAdd">新增</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table ref="table" :data="tableData" v-loading="tableLoading" border stripe height="100%">
          <el-table-column prop="account" label="企业账号" align="center" min-width="150" />
          <el-table-column prop="name" label="企业名称" align="center" min-width="150" />
          <el-table-column prop="tenantCode" label="租户编码" align="center" min-width="150" />
          <el-table-column prop="iconUrl" label="企业图标" align="center" min-width="150">
            <template slot-scope="scope">
              <el-image v-if="scope.row.iconUrl" :src="scope.row.iconUrl" :preview-src-list="[scope.row.iconUrl]" style="max-width: 70px" />
            </template>
          </el-table-column>
          <el-table-column prop="linkman" label="联系人" align="center" min-width="150" />
          <el-table-column prop="phone" label="联系电话" align="center" min-width="150" />
          <el-table-column prop="email" label="企业邮箱" align="center" min-width="150" />
          <el-table-column prop="createUser" label="创建人" align="center" min-width="100" />
          <el-table-column prop="createTime" label="创建时间" align="center" min-width="150" />
          <el-table-column prop="updateUser" label="更新人" align="center" min-width="100" />
          <el-table-column prop="updateTime" label="更新时间" align="center" min-width="150" />
          <el-table-column label="操作" align="center" min-width="120" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)"> 编辑 </el-button>
              <el-button type="text" @click="handleDelete(scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
    <add-modal :show.sync="showAdd" :detail="curRow" @close="closeModal" />
  </div>
</template>
<script>
import addModal from './addModal'
import api from '@/api/operate/account'
export default {
  components: { addModal },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      showAdd: false,
      curRow: null,
    }
  },
  created() {
    this.query()
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
      }
      this.tableLoading = true
      api
        .query(params)
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleQuery() {
      this.page.current = 1
      this.query()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
    handleAdd() {
      this.curRow = null
      this.showAdd = true
    },
    handleEdit(row) {
      this.curRow = _.cloneDeep(row)
      this.showAdd = true
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        api.delete({ id: row.id }).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
    closeModal() {
      this.showAdd = false
      this.query()
    },
  },
}
</script>
